<template>

  <div style="margin: 0px auto" class="content" id="Feltonquant">
    <img style="position: absolute; top: 40px;height: 24px;vertical-align: middle" src="../assets/img/robot/phone_back.png" @click="goBack()">
    <div class="">
      <h4 v-if="selectLanguage != 'English'" class="modal-title" id="myModalLabel">UPlus智能合约交易风险提示</h4>
      <h4 v-else class="modal-title" id="">UPlus Hedging Contract Trading Risk Warning</h4>
    </div>

    <div v-if="selectLanguage == '简体中文'">
      <p>一、在用户使用我方提供的智能合约交易服务前，请仔细阅读《UPlus智能合约交易协议》（以下简称“本协议”），一旦进行相关操作，视为用户已经阅读并接受本协议的全部条款。</p>
      <p style="margin-top: 10px">二、智能合约交易可能面临一定法域内的政策监管风险，用户方在进行交易之前，需在了解交易地域的政策监管背景的前提下审慎做出判断。用户应当自行负责且完全了解适用于数字资产及其场外交易的处置、监管以及征税的法律法规。</p>
      <p style="margin-top: 10px">三、合约交易具有杠杆交易属性，有高风险性，仅适合专业投资机构或投资经验丰富的人士，或者能够充分理解有关于智能合约交易的一切风险，能承受因投资失误而造成账户资金部分或者全部损失的人士。用户在我方进行智能合约交易完全是用户根据自身经济情况并在了解面临的相关风险后进行的自愿交易行为，与我方以及第三人无任何关系。</p>
      <p style="margin-top: 10px">四、用户在我方进行智能合约交易时，可能会获得较高的投资收益，但同时也存在较大的投资风险。为了使用户更好地了解其中的风险，根据有关法律法规、行政规章及相关国家政策，特向用户智能合约交易存在的风险进行提示，请用户认真详细阅读。用户在我方进行智能合约交易存在的风险包括但不限于：</p>
      <div style="margin-left: 20px">
        <p style="margin-top: 10px">（1）“数字资产”本身的风险：数字资产市场是全新的、未经确认的，而且可能不会增长。目前，数字资产主要由投机者大量使用，零售和商业市场使用相对较少。因此，数字资产价格易产生波动，并进而对数字资产投资产生不利影响。数字资产市场没有像股市那样的涨跌停限制，同时交易是24小时开放的。数字资产由于筹码较少，价格易受到主力交易者（即庄家）控制以及全球政府政策的影响而大幅波动，即有可能出现一天价格涨几倍的情况，同时也可能出现一天内价格跌去一半的情况。因此，因为数字资产本身的交易特性而给用户造成的经济损失全部由用户自行承担。</p>
        <p style="margin-top: 10px">（2）政策风险：因各国法律、法规和规范性文件的规定、出台或者修改，资金之间交易随时可能被暂停、或被禁止。因国家法律、法规和规范性文件的规定、出台或者修改，导致数字资产的交易被暂停、或者禁止的，因此造成的经济损失全部由用户自行承担。</p>
        <p style="margin-top: 10px">（3）互联网及技术风险：我方不能保证我方包含的全部信息、程序、文本等完全安全，不受任何病毒、木马等恶意程序的干扰和破坏，故用户登录、使用我方任何服务或下载及使用该下载的任何程序、信息、数据等均是用户个人的决定并自行承担风险及可能产生的损失。同时使用互联网形式的交易系统亦存有风险，包括但不限于软件，硬件和互联网连接的失败等。由于我方不能控制互联网的可靠性和可用性，我方不会对失真，延误和连接失败而承担任何责任。</p>
        <p style="margin-top: 10px">（4）不可抗力风险：我方对由于信息网络设备维护、信息网络连接故障、电脑、通讯或其他交易系统故障、电力故障，遭遇黑客攻击、天气原因、意外事故、罢工、劳动争议、暴乱、起义、骚乱、生产力或生产资料不足、火灾、洪水、风暴、爆炸、战争、银行或其他合作方原因、数字资产市场崩溃、政府行为、司法或行政机关的命令、其他不在我方可控范围内或我方无能力控制的行为或第三方的原因而造成的不能服务或延迟服务，或者因以上原因而造成的用户的损失，我方不承担任何责任。</p>
        <p style="margin-top: 10px">（5）市场风险。投资收益的多少和市场有着密切的联系，比如经济景气与否、国际经济情况的变化等，这些都是投资理财风险，而且几乎所有的投资资产都会受到市场的影响。由于市场风险造成的经济损失全部由用户自行承担，我方不承担任何责任。</p>
        <p style="margin-top: 10px">（6）其他风险：由于用户忘记账号名称或密码、操作不当，投资决策失误等原因导致用户发生亏损；网上委托，他人进行恶意操作而造成的损失；委托他人代理用户在我方进行合约对冲交易造成的损失；因意外事件以及非因我方原因造成的损失；其他在使用条款及其他协议内列明的风险等；上述损失都将由用户自行承担。</p>
      </div>
      <p style="margin-top: 10px">五、当UPlus账户燃料费低于10U时，会导致智能合约交易自动关闭，停止对策略的监控运行，会产生较大风险。为避免此种情况发生，开启智能合约交易时燃料费须大于本金的10%；如因燃料费欠费产生的风险所导致用户的损失都由用户承担。</p>
      <p style="margin-top: 10px">六、如因用户的的行为或原因使我方或及其关联公司遭受任何损失的，用户应当承担全部损失赔偿责任并在我方要求的时限内完成费用支付。</p>
      <p style="margin-top: 10px">七、本协议未约定之内容,参照交易系统通用的用户协议或者类似协议内容来执行。</p>
      <p style="margin-top: 10px">八、随着市场经营情况的变化，我方有权更改本协议内容和，无须另行通知；我方对于本协议内容有最终解释权。</p>
    </div>
    <div v-if="selectLanguage == '繁体中文'">
      <p>一、在用戶使用我方提供的智能合約交易服務前，請仔細閱讀《UPlus智能合約交易協議》（以下簡稱“本協議”），一旦進行相關操作，視為用戶已經閱讀並接受本協議的全部條款。 </p>
      <p style="margin-top: 10px">二、智能合約交易可能面臨一定法域內的政策監管風險，用戶方在進行交易之前，需在了解交易地域的政策監管背景的前提下審慎做出判斷。用戶應當自行負責且完全了解適用於數字資產及其場外交易的處置、監管以及徵稅的法律法規。 </p>
      <p style="margin-top: 10px">三、合約交易具有槓桿交易屬性，有高風險性，僅適合專業投資機構或投資經驗豐富的人士，或者能夠充分理解有關於智能合約交易的一切風險，能承受因投資失誤而造成賬戶資金部分或者全部損失的人士。用戶在我方進行智能合約交易完全是用戶根據自身經濟情況並在了解面臨的相關風險後進行的自願交易行為，與我方以及第三人無任何關係。 </p>
      <p style="margin-top: 10px">四、用戶在我方進行智能合約交易時，可能會獲得較高的投資收益，但同時也存在較大的投資風險。為了使用戶更好地了解其中的風險，根據有關法律法規、行政規章及相關國家政策，特向用戶智能合約交易存在的風險進行提示，請用戶認真詳細閱讀。用戶在我方進行智能合約交易存在的風險包括但不限於：</p>
      <div style="margin-left: 20px">
        <p style="margin-top: 10px">（1）“數字資產”本身的風險：數字資產市場是全新的、未經確認的，而且可能不會增長。目前，數字資產主要由投機者大量使用，零售和商業市場使用相對較少。因此，數字資產價格易產生波動，並進而對數字資產投資產生不利影響。數字資產市場沒有像股市那樣的漲跌停限制，同時交易是24小時開放的。數字資產由於籌碼較少，價格易受到主力交易者（即莊家）控制以及全球政府政策的影響而大幅波動，即有可能出現一天價格漲幾倍的情況，同時也可能出現一天內價格跌去一半的情況。因此，因為數字資產本身的交易特性而給用戶造成的經濟損失全部由用戶自行承擔。 </p>
        <p style="margin-top: 10px">（2）政策風險：因各國法律、法規和規範性文件的規定、出台或者修改，資金之間交易隨時可能被暫停、或被禁止。因國家法律、法規和規範性文件的規定、出台或者修改，導致數字資產的交易被暫停、或者禁止的，因此造成的經濟損失全部由用戶自行承擔。 </p>
        <p style="margin-top: 10px">（3）互聯網及技術風險：我方不能保證我方包含的全部信息、程序、文本等完全安全，不受任何病毒、木馬等惡意程序的干擾和破壞，故用戶登錄、使用我方任何服務或下載及使用該下載的任何程序、信息、數據等均是用戶個人的決定並自行承擔風險及可能產生的損失。同時使用互聯網形式的交易系統亦存有風險，包括但不限於軟件，硬件和互聯網連接的失敗等。由於我方不能控制互聯網的可靠性和可用性，我方不會對失真，延誤和連接失敗而承擔任何責任。 </p>
        <p style="margin-top: 10px">（4）不可抗力風險：我方對由於信息網絡設備維護、信息網絡連接故障、電腦、通訊或其他交易系統故障、電力故障，遭遇黑客攻擊、天氣原因、意外事故、罷工、勞動爭議、暴亂、起義、騷亂、生產力或生產資料不足、火災、洪水、風暴、爆炸、戰爭、銀行或其他合作方原因、數字資產市場崩潰、政府行為、司法或行政機關的命令、其他不在我方可控範圍內或我方無能力控制的行為或第三方的原因而造成的不能服務或延遲服務，或者因以上原因而造成的用戶的損失，我方不承擔任何責任。 </p>
        <p style="margin-top: 10px">（5）市場風險。投資收益的多少和市場有著密切的聯繫，比如經濟景氣與否、國際經濟情況的變化等，這些都是投資理財風險，而且幾乎所有的投資資產都會受到市場的影響。由於市場風險造成的經濟損失全部由用戶自行承擔，我方不承擔任何責任。 </p>
        <p style="margin-top: 10px">（6）其他風險：由於用戶忘記賬號名稱或密碼、操作不當，投資決策失誤等原因導致用戶發生虧損；網上委託，他人進行惡意操作而造成的損失；委託他人代理用戶在我方進行合約對沖交易造成的損失；因意外事件以及非因我方原因造成的損失；其他在使用條款及其他協議內列明的風險等；上述損失都將由用戶自行承擔。 </p>
      </div>
      <p style="margin-top: 10px">五、當UPlus賬戶燃料費低於10U時，會導致智能合約交易自動關閉，停止對策略的監控運行，會產生較大風險。為避免此種情況發生，開啟智能合約交易時燃料費須大於本金的10%；如因燃料費欠費產生的風險所導致用戶的損失都由用戶承擔。 </p>
      <p style="margin-top: 10px">六、如因用戶的的行為或原因使我方或及其關聯公司遭受任何損失的，用戶應當承擔全部損失賠償責任並在我方要求的時限內完成費用支付。 </p>
      <p style="margin-top: 10px">七、本協議未約定之內容,參照交易系統通用的用戶協議或者類似協議內容來執行。 </p>
      <p style="margin-top: 10px">八、隨著市場經營情況的變化，我方有權更改本協議內容和，無須另行通知；我方對於本協議內容有最終解釋權。 </p>
    </div>
    <div v-if="selectLanguage == 'English'">
      <p>1. Before users use the hedging contract trading services provided by us, please read the "UPlus Hedging Contract Trading Agreement" (hereinafter referred to as "this Agreement") carefully. Once relevant operations are performed, it is deemed that the user has read and accepted this the full terms of the agreement. </p>
      <p style="margin-top: 10px">2. Hedging contract transactions may face policy regulatory risks in certain jurisdictions. Before conducting transactions, users should make prudent judgments on the premise of understanding the policy and regulatory background of the trading region. . Users should be responsible for and fully understand the laws and regulations applicable to the disposal, supervision and taxation of digital assets and their over-the-counter transactions. </p>
      <p style="margin-top: 10px">3. contract trading has leveraged trading properties and high risk, and is only suitable for professional investment institutions or people with rich investment experience, or who can fully understand all risks related to hedging contract trading. , those who can afford part or all of the loss of account funds due to investment mistakes. The user's hedging contract transaction with us is entirely a voluntary transaction made by the user according to his own economic situation and after understanding the relevant risks he faces, and has nothing to do with us and third parties. </p>
      <p style="margin-top: 10px">4. users may obtain higher investment returns when conducting hedging contract transactions with us, but at the same time there are greater investment risks. In order to enable users to better understand the risks involved, according to relevant laws and regulations, administrative rules and relevant national policies, users are specially reminded of the risks existing in hedging contract transactions, and users are requested to read them carefully. The risks of the user's hedging contract transactions with us include but are not limited to:</p>
      <div style="margin-left: 20px">
        <p style="margin-top: 10px">(1) Risks of "digital assets" themselves: The digital asset market is new, unconfirmed, and may not grow. Currently, digital assets are heavily used mainly by speculators, with relatively little use in retail and commercial markets. Therefore, the price of digital assets is prone to fluctuations, which in turn adversely affects investment in digital assets. The digital asset market does not have limit-up and down restrictions like the stock market, and trading is open 24 hours a day. Due to the small amount of chips, the price of digital assets is susceptible to the control of major traders (ie bookmakers) and the influence of global government policies and fluctuates greatly, that is, the price may rise several times a day, and at the same time, the price may drop by half in one day. Case. Therefore, all economic losses caused to users due to the transaction characteristics of digital assets are borne by users themselves. </p>
        <p style="margin-top: 10px">(2) Policy risk: Due to the provisions, promulgation or revision of laws, regulations and normative documents of various countries, transactions between funds may be suspended or prohibited at any time. If the transaction of digital assets is suspended or prohibited due to the provisions, promulgation or revision of national laws, regulations and normative documents, all economic losses caused thereby shall be borne by the user. </p>
        <p style="margin-top: 10px">(3) Internet and technical risks: We cannot guarantee that all the information, programs, texts, etc. contained in our company are completely safe, and will not be interfered by any malicious programs such as viruses, Trojan horses, etc. Therefore, the user's personal decision to log in, use any of our services or download and use any program, information, data, etc. downloaded is the user's personal decision and bear the risk and possible losses. At the same time, the use of Internet-based trading systems also has risks, including but not limited to software, hardware and Internet connection failures. As we have no control over the reliability and availability of the Internet, we cannot be held responsible for distortions, delays and connection failures. </p>
        <p style="margin-top: 10px">(4) Risk of Force Majeure: We are not liable for the risk of hacker attack, weather, etc. , accidents, strikes, labor disputes, riots, uprisings, riots, lack of productivity or means of production, fires, floods, storms, explosions, wars, bank or other partner causes, digital asset market collapse, government actions, judicial or administrative authorities We do not assume any responsibility for the inability to serve or delay the service due to the order, other acts beyond our control or beyond our control, or for third-party reasons, or for the loss of the user caused by the above reasons. . </p>
        <p style="margin-top: 10px">(5) Market risk. The amount of investment income is closely related to the market, such as economic prosperity, changes in international economic conditions, etc. These are investment and financial risks, and almost all investment assets will be affected by the market. All economic losses caused by market risks shall be borne by the users themselves, and we shall not bear any responsibility. </p>
        <p style="margin-top: 10px">(6) Other risks: loss due to user forgetting account name or password, improper operation, investment decision mistakes, etc.; online entrustment, loss caused by malicious operation of others ; Losses caused by entrusting others to act on behalf of users to perform contract hedging transactions with us; losses caused by accidents and non-our reasons; other risks listed in the terms of use and other agreements; the above losses will be borne by users themselves . </p>
      </div>
      <p style="margin-top: 10px">5. When the fuel cost of the UPlus account is lower than 10U, the hedging contract transaction will be automatically closed, and the monitoring and operation of the strategy will be stopped, which will cause great risks. In order to avoid this situation, the gas fee must be greater than 10% of the principal when opening a hedging contract transaction; the user will be responsible for any loss caused by the risk of arrears in gas fee. </p>
      <p style="margin-top: 10px">6. If we or its affiliates suffer any loss due to the user's behavior or reasons, the user shall be liable for all losses and within the time limit required by us Complete fee payment. </p>
      <p style="margin-top: 10px">7. For the content not stipulated in this agreement, refer to the general user agreement of the trading system or similar agreement content. </p>
      <p style="margin-top: 10px">8. With the changes in market operation, we have the right to change the content of this agreement without prior notice; we have the final right to interpret the content of this agreement. </p>
    </div>
  </div>

</template>

<script>
export default {
  name: "HedgingContractTransactionAgreement",

  data(){
    return{
      selectLanguage:'简体中文',
    }
  },

  created() {
    if (localStorage.getItem('language')){
      this.selectLanguage = localStorage.getItem('language')
    }
  },

  methods:{
    goBack() {
      // this.$router.back()
      sessionStorage.setItem('goBack', '1')
      this.$router.go(-1);
      // window.history.back()
    }
  }
}
</script>

<style scoped>
.modal-title {
  text-align: center;
  font-size: 18px;
  margin-top: 50px;
}

.modal-body > h5, p {
  font-size: 14px;
  margin: 0px;
  line-height: 22px;
}

@media screen and (max-width: 900px) {
  .content {
    width: 90%;
    padding: 30px 0px;
    margin: 0 auto;
    position: relative;
    bottom: 20px;
  }
}

@media screen and (min-width: 900px) {
  .content {
    width: 50%;
    padding: 30px;
    margin: 0 auto;
    position: relative;
    bottom: 20px;
  }
}
</style>
